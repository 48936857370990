var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-content"},[_c('alert',{attrs:{"value":_vm.showAlert,"type":_vm.alertType,"canClose":true},on:{"close":_vm.hideAlert}},[_c('ul',_vm._l((_vm.alertMessages),function(msg,idx){return _c('li',{key:("msg-" + idx)},[_vm._v(" "+_vm._s(msg)+" ")])}),0)]),(_vm.hasWriteAccess())?_c('c-btn',{staticClass:"btn-red",on:{"click":function($event){$event.stopPropagation();return _vm.openRoomDialog()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-account-plus ")]),_c('span',[_vm._v("Ajouter une nouvelle salle")])],1):_vm._e(),_c('c-data-table',{attrs:{"headers":_vm.headers,"items":_vm.rooms,"loading":_vm.isLoading,"sort-by":['site', 'name'],"items-per-page":_vm.defaultItemsPerPage,"footer-props":{ itemsPerPageOptions: _vm.defaultItemsPerPageWithAll, disablePagination: _vm.isLoading }},scopedSlots:_vm._u([{key:"item.soinsTypeIds",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"chips-group"},_vm._l((item.soinsTypeIds),function(consultationId,i){return _c('c-chip',{key:("consultationType-" + i),class:("type-" + (_vm.getConsultationShort(consultationId)))},[_c('span',{staticClass:"bullet"}),_vm._v(" "+_vm._s(_vm.getConsultationName(consultationId))+" ")])}),1)]}},{key:"item.openTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.openTime+' - '+item.closeTime)+" ")]}},{key:"item.isActive",fn:function(ref){
var item = ref.item;
return [(item.isActive)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check-circle-outline ")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-minus-circle-outline ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table-row-actions"},[(_vm.hasWriteAccess())?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"action-edit",on:{"click":function($event){$event.stopPropagation();return _vm.editItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil-outline ")])]}}],null,true)},[_c('span',[_vm._v("Modifier")])]):_vm._e(),(_vm.hasWriteAccess())?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"action-delete",on:{"click":function($event){$event.stopPropagation();return _vm.displayRoomDeleteConfirmDialog(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete-outline ")])]}}],null,true)},[_c('span',[_vm._v("Supprimer")])]):_vm._e()],1)]}},{key:"no-data",fn:function(){return [(!_vm.isLoading && !_vm.rooms.length)?_c('p',{staticClass:"leader"},[_vm._v("Aucun salle trouvé")]):_vm._e(),_c('c-btn',{attrs:{"loading":_vm.isLoading},on:{"click":function($event){return _vm.getAllRooms()}}},[_vm._v(" Actualiser ")])]},proxy:true}],null,true)}),_c('confirm',{attrs:{"visible":_vm.showConfirm,"title":("Supprimer salle " + (_vm.selectedRoom.name) + " à " + (_vm.selectedRoom.site)),"message":"Cette action ne peut être annulée. Voulez-vous continuer et supprimer la salle ?"},on:{"close":function($event){return _vm.confirmDeleteCallback($event)}}}),_c('room-dialog',{attrs:{"visible":_vm.dialog,"roomItem":_vm.selectedRoom,"errorMessages":_vm.errorMessages,"isLoading":_vm.isLoading},on:{"close":function($event){return _vm.closeRoomDialog()},"saveClick":function($event){return _vm.save()}}}),_c('v-overlay',{attrs:{"value":!_vm.ready,"absolute":true}},[_c('v-progress-circular',{staticStyle:{"position":"fixed","bottom":"50%"},attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }