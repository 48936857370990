import { LayoutService } from '@/services/layout-service'
import { Component, Vue } from 'vue-property-decorator'
import Confirm from '@/components/shared/Confirm/confirm.vue'
import RoomDialog from '@/components/shared/Dialogs/Rooms/RoomDialog.vue'
import Alert from '@/components/shared/Alert.vue'
import { ErrorService } from '@/services/error.service'
import { RoomService } from '@/services/room-service'
import { RoomModel } from '@/models/rooms-model'
import { roomHeadersItems, soinsTypes } from '../../Constants'
import { defaultItemsPerPage, defaultItemsPerPageWithAll } from '@/shared/constants/Constants'
import Commons from '@/components/shared/Helpers/commons'
import { AdministrationHelper } from '../../AdministrationHelper'
import { ModuleAuthorisationManager } from '@/services/module-authorisation-manager'

@Component({
  components: {
    Alert,
    Confirm,
    RoomDialog
  }
})
export default class RoomsManage extends Vue {
  private layoutService = LayoutService.getInstance()
  private roomService = RoomService.getInstance()

  public dialog = false
  public showConfirm = false
  public isLoading = false
  public errorMessages: string[] = []

  public alertMessages: string[] = []
  public showAlert = false
  public alertType: 'success' | 'error' | 'warning' = 'success'

  public headers = roomHeadersItems
  public selectedRoom: RoomModel = { isActive: true }
  public defaultItem: RoomModel = { isActive: true, openTime: '08:00', closeTime: '17:00' }
  public rooms: RoomModel[] = []
  public selectedRoomInitialSoinTypes: number[] = []

  private ready = false
  public defaultItemsPerPageWithAll = defaultItemsPerPageWithAll
  public defaultItemsPerPage = defaultItemsPerPage

  public mounted () {
    this.ready = false
    this.layoutService.updateDrawerList(AdministrationHelper.GetAdminNavItems())
    this.getAllRooms()
    this.selectedRoom = { ...this.defaultItem }
  }

  public destroyed () {
    this.layoutService.updateDrawerList([])
  }

  public getAllRooms () {
    this.ready = false
    this.roomService.getAllRooms().then((rooms) => {
      this.rooms = rooms
    }).catch(async (errs) => {
      const res = await ErrorService.handleError(errs)
      this.updateAlertErrorMessages(res)
    }).finally(() => {
      this.ready = true
    })
  }

  public getConsultationName (consultationId: number) {
    return soinsTypes.find(x => x.value === consultationId)?.text
  }

  public getConsultationShort (consultationId: number) {
    return soinsTypes.find(x => x.value === consultationId)?.short
  }

  public openRoomDialog () {
    this.selectedRoom = { ...this.defaultItem }
    this.dialog = true
  }

  public editItem (item: RoomModel) {
    this.selectedRoom = item
    this.selectedRoomInitialSoinTypes = this.selectedRoom.soinsTypeIds?.map(i => i) ?? []
    this.dialog = true
  }

  public displayRoomDeleteConfirmDialog (room: RoomModel) {
    this.selectedRoom = room
    this.showConfirm = true
  }

  public async confirmDeleteCallback (value: boolean) {
    this.showConfirm = false
    if (value) {
      this.isLoading = true
      const results = await this.roomService.deleteRoomItem(this.selectedRoom.id)
        .catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.updateAlertErrorMessages(res)
        }).finally(() => {
          this.isLoading = false
          this.selectedRoom = { ...this.defaultItem }
        })

      if (results) {
        this.errorMessages = []
        this.selectedRoom = this.defaultItem
        this.getAllRooms()
      }
    }
  }

  public closeRoomDialog () {
    this.isLoading = false
    this.selectedRoom = { ...this.defaultItem }
    this.errorMessages = []
    this.dialog = false
    this.getAllRooms()
  }

  public async save () {
    this.errorMessages = []
    this.isLoading = true
    const results = await this.roomService.addUpdateRoomItem(this.selectedRoom)
      .catch(async (errs) => {
        const res = await ErrorService.handleError(errs)
        this.errorMessages = res.errors
      }).finally(() => {
        this.isLoading = false
      })
    if (results) {
      if (this.selectedRoom.id) {
        // check if the soins changed, we don't care about the order, so sort
        const roomSoins = (this.selectedRoom.soinsTypeIds?.map(i => i) ?? []).sort((a, b) => a - b)
        const roomInitialSoins = (this.selectedRoomInitialSoinTypes?.map(i => i) ?? []).sort((a, b) => a - b)

        if (!Commons.checkSoinTypesMatch(roomSoins, roomInitialSoins)) {
          this.updateAlertWarningMessage('Modifier les soins d\'une salle peut entraîner des incohérences dans les plannings et dans l\'agenda.')
        }
      }

      this.errorMessages = []
      this.dialog = false
      this.getAllRooms()
    }
  }

  public hideAlert () {
    this.alertMessages = []
    this.showAlert = false
  }

  private updateAlertErrorMessages (res: { errors: any[]; title: string }) {
    this.alertMessages = res.errors
    this.alertType = 'error'
    this.showAlert = res.errors.length > 0
  }

  private updateAlertWarningMessage (message: string) {
    this.alertMessages.push(message)
    this.alertType = 'warning'
    this.showAlert = true
  }

  public hasWriteAccess () {
    return ModuleAuthorisationManager.HasAccess('admin.roomManage.writeOperations')
  }
}
