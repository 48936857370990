import { AppUser } from '@/models/app-user-dto'
import { RoomModel, RoomPlanningDayModel, RoomPlanningModel, RoomPlanningPeriodModel } from '@/models/rooms-model'

export default class RoomHelpers {
  public static GetCommonSoinsTypesWithNurse (allInfirmieres: AppUser[], roomSoinsTypeIds: number[], nurseId?: string) {
    if (nurseId) {
      const nurse = allInfirmieres.find(i => i.id.toLowerCase() === nurseId.toLowerCase())
      if (nurse) {
        return RoomHelpers.GetCommonSoinsTypes(roomSoinsTypeIds, nurse.soinsTypeIds)
      }
    }
  }

  public static GetCommonSoinsTypes (roomSoinsTypeIds: number[], nurseSoinsTypeIds: number[]) {
    return roomSoinsTypeIds.filter(value => nurseSoinsTypeIds.includes(value))
  }

  public static MapRoomPlanningPeriod (allInfirmieres: AppUser[], allRooms: RoomModel[], roomPlanningPeriod: RoomPlanningPeriodModel) {
    if (roomPlanningPeriod.roomPlannings) {
      roomPlanningPeriod.roomPlannings.sort(function (a, b) {
        return (a.site ?? '').localeCompare(b.site ?? '') || (a.room ?? '').localeCompare(b.room ?? '')
      })
    }
    RoomHelpers.MapRoomPlannings(allInfirmieres, allRooms, roomPlanningPeriod.roomPlannings)
  }

  private static MapRoomPlannings (allInfirmieres: AppUser[], allRooms: RoomModel[], roomPlannings?: RoomPlanningModel[]) {
    if (roomPlannings) {
      let lastSite = ''
      const allPlannings = roomPlannings
      roomPlannings.forEach((roomPlanning: RoomPlanningModel) => {
        if (roomPlanning.site === lastSite) {
          roomPlanning.rowspan = undefined
        } else {
          lastSite = roomPlanning.site ?? ''
          roomPlanning.rowspan = allPlannings.filter(p => p.siteId === roomPlanning.siteId).length
        }
        roomPlanning.lu = RoomHelpers.GetInfirmiereDetails(allInfirmieres, roomPlanning.day1AssignedNurseId)
        roomPlanning.ma = RoomHelpers.GetInfirmiereDetails(allInfirmieres, roomPlanning.day2AssignedNurseId)
        roomPlanning.me = RoomHelpers.GetInfirmiereDetails(allInfirmieres, roomPlanning.day3AssignedNurseId)
        roomPlanning.je = RoomHelpers.GetInfirmiereDetails(allInfirmieres, roomPlanning.day4AssignedNurseId)
        roomPlanning.ve = RoomHelpers.GetInfirmiereDetails(allInfirmieres, roomPlanning.day5AssignedNurseId)
        const room = allRooms.find(i => i.id === roomPlanning.roomId)
        if (room) {
          roomPlanning.roomSoinsTypeIds = room.soinsTypeIds
        }
      })
    }
  }

  private static GetInfirmiereDetails (allInfirmieres: AppUser[], id?: string): RoomPlanningDayModel {
    if (id) {
      const nurse = allInfirmieres.find(i => i.id.toLowerCase() === id.toLowerCase())
      if (nurse) {
        return {
          user: nurse.fullName,
          types: nurse.soinsTypeIds
        }
      }
    }
    return {
      user: '',
      types: []
    }
  }

  public static CanEditPlanning (planning: RoomPlanningPeriodModel) {
    return true
  }

  public static CanDeletePlanning (planning: RoomPlanningPeriodModel) {
    const from = new Date(planning.dateRange?.from!)
    const to = new Date(planning.dateRange?.to!)
    const today = new Date()
    return (to < today || from > today) && to > today
  }
}
