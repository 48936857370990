import { LayoutService } from '@/services/layout-service'
import { Component, Vue } from 'vue-property-decorator'
import RoomsManage from '@/views/Administration/Rooms/RoomsManage/RoomsManage.vue'
import RoomsPlanning from '@/views/Administration/Rooms/RoomsPlanning/RoomsPlanning.vue'
import { AdministrationHelper } from '../AdministrationHelper'

@Component({
  components: {
    RoomsManage,
    RoomsPlanning
  }
})
export default class Rooms extends Vue {
  public tab: any = null
  public tabs = ['Gestion des salles', 'Planning des salles']
  private layoutService = LayoutService.getInstance()

  public mounted () {
    this.layoutService.updateDrawerList(AdministrationHelper.GetAdminNavItems())
    if (sessionStorage.getItem('goToPlanningDesSalles')) {
      sessionStorage.removeItem('goToPlanningDesSalles')
      this.tab = 1
    }
  }
}
