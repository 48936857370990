import http from '@/http-client'
import { RoomModel, RoomPlanningPeriodModel } from '@/models/rooms-model'

export class RoomService {
  private static instance: RoomService

  public static getInstance (): RoomService {
    if (!RoomService.instance) {
      RoomService.instance = new RoomService()
    }
    return RoomService.instance
  }

  // Rooms
  public async getAllRooms () {
    const response = await http.get('/room')
    return (response.data) as RoomModel[]
  }

  public async getAllActiveRooms () {
    const response = await http.get('/room/active')
    return (response.data) as RoomModel[]
  }

  public async addUpdateRoomItem (room: RoomModel) {
    const payload = { ...room, ...room.siteSelected }
    const response = await http.post('/room', payload)
    return response.data as RoomModel
  }

  public async deleteRoomItem (roomItemId?: number) {
    if (!roomItemId) return 0
    const response = await http.delete(`/room/${roomItemId}`)
    return response.data as number
  }

  public async getPlanningPeriod (planningPeriodId: number) {
    const response = await http.get(`/room/planning/${planningPeriodId}`)
    return (response.data) as RoomPlanningPeriodModel
  }

  public async getAllRoomPlannings () {
    const response = await http.get('/room/planning')
    return (response.data) as RoomPlanningPeriodModel[]
  }

  public async addUpdateRoomPlanningPeriod (roomPlanningPeriod: RoomPlanningPeriodModel) {
    return await http.post('/room/planning', roomPlanningPeriod)
  }

  public async deleteRoomPlanningPeriod (id?: number) {
    if (!id) return 0
    const response = await http.delete(`/room/planning/${id}`)
    return response.data as number
  }

  public async getPlanningPeriods (from: string, to: string) {
    const response = await http.get(`/room/planning-range?from=${from}&to=${to}`)
    return (response.data) as RoomPlanningPeriodModel[]
  }
}
