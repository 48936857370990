var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-content"},[_c('alert',{attrs:{"value":!!_vm.errorMessages.length,"type":"error","canClose":true},on:{"close":_vm.hideAlert}},[_c('ul',_vm._l((_vm.errorMessages),function(msg,idx){return _c('li',{key:("msg-" + idx)},[_vm._v(" "+_vm._s(msg)+" ")])}),0)]),(_vm.hasWriteAccess())?_c('c-btn',{staticClass:"btn-red",attrs:{"loading":!_vm.ready},on:{"click":function($event){return _vm.add()}}},[_c('v-icon',[_vm._v(" mdi-plus ")]),_c('span',[_vm._v("Ajouter une nouvelle période de planning")])],1):_vm._e(),_c('c-data-table',{attrs:{"headers":_vm.headers,"items":_vm.roomPlannings,"hide-default-footer":_vm.roomPlannings.length <= 10,"sort-by":['dateRange.from'],"sort-desc":[true],"show-expand":"","item-key":"id","mobile-breakpoint":600,"item-class":_vm.computePlanningRowClass,"loading":_vm.isLoading,"items-per-page":_vm.defaultItemsPerPage,"footer-props":{ itemsPerPageOptions: _vm.defaultItemsPerPageWithAll, disablePagination: _vm.isLoading }},scopedSlots:_vm._u([{key:"item.dateRange.from",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date-fr")(item.dateRange.from))+" ")]}},{key:"item.dateRange.to",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date-fr")(item.dateRange.to))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table-row-actions"},[(_vm.canEditPlanning(item))?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"action-edit",on:{"click":function($event){return _vm.edit(item.id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil-outline ")])]}}],null,true)},[_c('span',[_vm._v("Modifier")])]):_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"action-edit",on:{"click":function($event){return _vm.edit(item.id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye-circle-outline ")])]}}],null,true)},[_c('span',[_vm._v("Visualiser")])]),(_vm.hasWriteAccess())?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"action-edit",on:{"click":function($event){return _vm.duplicate(item.id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-plus-circle-multiple-outline ")])]}}],null,true)},[_c('span',[_vm._v("Dupliquer")])]):_vm._e(),(_vm.canDeletePlanning(item))?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"action-delete",on:{"click":function($event){return _vm.displayDeleteConfirmDialog(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete-outline ")])]}}],null,true)},[_c('span',[_vm._v("Supprimer")])]):_vm._e()],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('c-data-table',{attrs:{"headers":_vm.planningHeaders,"items":item.roomPlannings,"mobile-breakpoint":600,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{class:_vm.computeRoomPlanningRowClass(item)},[(!!item.rowspan)?_c('td',{attrs:{"rowspan":item.rowspan}},[_c('strong',[_c('em',[_vm._v(_vm._s(item.site))])])]):_vm._e(),_c('td',[_c('strong',[_vm._v(_vm._s(item.room))])]),_vm._l((['lu', 'ma', 'me', 'je', 've']),function(day){return _c('td',{key:day},[_c('div',{staticClass:"user-name"},[_vm._v(" "+_vm._s(item[day]['user'])+" ")]),_c('div',{staticClass:"bullets-group"},_vm._l((_vm.getCommonSoinsTypes(item['roomSoinsTypeIds'], item[day]['types'])),function(soinType){return _c('v-tooltip',{key:soinType,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({class:("bullet type-" + (_vm.getTypeNameShort(soinType)))},'span',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getTypeName(soinType)))])])}),1)])})],2)]}}],null,true)})],1)]}}],null,true)}),_c('v-overlay',{attrs:{"value":!_vm.ready,"absolute":true}},[_c('v-progress-circular',{staticStyle:{"position":"fixed","bottom":"50%"},attrs:{"indeterminate":"","size":"64"}})],1),_c('confirm',{attrs:{"visible":_vm.showConfirm,"title":"Supprimer ce planning ","message":"Cette action ne peut être annulée. Voulez-vous continuer et supprimer ce planning ?"},on:{"close":function($event){return _vm.confirmDeleteCallback($event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }