import { Component, Vue, Watch } from 'vue-property-decorator'
import Confirm from '@/components/shared/Confirm/confirm.vue'
import Alert from '@/components/shared/Alert.vue'
import { RoomService } from '@/services/room-service'
import { AuthService } from '@/services/auth-service'
import { ErrorService } from '@/services/error.service'
import { RoomModel, RoomPlanningPeriodModel, RoomPlanningModel } from '@/models/rooms-model'
import { AppUser } from '@/models/app-user-dto'
import { roomPlanningPeriodHeadersItems, roomPlanningHeadersItems, getSoinName, getSoinNameShort } from '../../Constants'
import { defaultItemsPerPage, defaultItemsPerPageWithAll } from '@/shared/constants/Constants'
import RoomHelpers from '@/components/shared/Helpers/RoomHelpers'
import Commons from '@/components/shared/Helpers/commons'
import { ModuleAuthorisationManager } from '@/services/module-authorisation-manager'

@Component({
  components: {
    Confirm,
    Alert
  }
})
export default class RoomsPlanning extends Vue {
  private roomService = RoomService.getInstance()
  private authService = AuthService.getInstance()

  public roomPlannings: RoomPlanningPeriodModel[] = []
  public selectedRoomPlanning: RoomPlanningPeriodModel = {}
  public rooms: RoomModel[] = []
  public allInfirmieres: AppUser[] = []

  public errorMessages: string[] = []
  public headers = roomPlanningPeriodHeadersItems
  public planningHeaders = roomPlanningHeadersItems

  public isLoading = false
  private ready = false
  private readyRoomPlannings = false
  private readyRooms = false
  private readyUsers = false
  public showConfirm = false
  public defaultItemsPerPageWithAll = defaultItemsPerPageWithAll
  public defaultItemsPerPage = defaultItemsPerPage

  public mounted () {
    this.ready = false
    this.getAllRoomPlannings()
    this.getAllRooms()
    this.getAllInfirmieres()
  }

  private updateReadyState () {
    this.ready = this.readyRoomPlannings && this.readyRooms && this.readyUsers
    if (this.ready) {
      this.mapRoomPlanningPeriods(this.roomPlannings)
    }
  }

  @Watch('readyRoomPlannings')
  public readyRoomPlanningssChanged () {
    this.updateReadyState()
  }

  @Watch('readyRooms')
  public readyRoomsChanged () {
    this.updateReadyState()
  }

  @Watch('readyUsers')
  public readyUsersChanged () {
    this.updateReadyState()
  }

  public async getAllInfirmieres () {
    this.readyUsers = false
    await this.authService.getAllInfirmiereGroupUsers(true).then((users) => {
      this.allInfirmieres = users
    }).catch(async (errs) => {
      const res = await ErrorService.handleError(errs)
      this.errorMessages = res.errors
    }).finally(() => {
      this.readyUsers = true
    })
  }

  public getAllRooms () {
    this.readyRooms = false
    this.roomService.getAllRooms().then((rooms) => {
      this.rooms = rooms
    }).catch(async (errs) => {
      const res = await ErrorService.handleError(errs)
      this.errorMessages = res.errors
    }).finally(() => {
      this.readyRooms = true
    })
  }

  public getAllRoomPlannings () {
    this.readyRoomPlannings = false
    this.roomService.getAllRoomPlannings().then((roomPlannings) => {
      this.roomPlannings = roomPlannings
      Commons.setRowClasses(this.roomPlannings)
    }).catch(async (errs) => {
      const res = await ErrorService.handleError(errs)
      this.errorMessages = res.errors
    }).finally(() => {
      this.readyRoomPlannings = true
    })
  }

  private mapRoomPlanningPeriods (roomPlanningPeriods: RoomPlanningPeriodModel[]) {
    roomPlanningPeriods.forEach((roomPlanningPeriod: RoomPlanningPeriodModel) => {
      RoomHelpers.MapRoomPlanningPeriod(this.allInfirmieres, this.rooms, roomPlanningPeriod)
    })
  }

  public computePlanningRowClass (item: RoomPlanningPeriodModel) {
    const anyErrors = this.hasPlanningPeriodErrors(item)
    if (anyErrors) {
      return item.rowClass + ' row-error'
    }
    return item.rowClass
  }

  public computeRoomPlanningRowClass (item: RoomPlanningModel) {
    const anyErrors = this.hasPlanningErrors(item)
    if (anyErrors) {
      return 'row-error'
    }
    return ''
  }

  private hasPlanningPeriodErrors (item: RoomPlanningPeriodModel) {
    return item.roomPlannings?.some(r => this.hasPlanningErrors(r))
  }

  private hasPlanningErrors (item: RoomPlanningModel) {
    const monday = this.getCommonSoinsTypes(item.roomSoinsTypeIds ?? [], item.lu?.types ?? [])
    const tuesday = this.getCommonSoinsTypes(item.roomSoinsTypeIds ?? [], item.ma?.types ?? [])
    const wednesday = this.getCommonSoinsTypes(item.roomSoinsTypeIds ?? [], item.me?.types ?? [])
    const thursday = this.getCommonSoinsTypes(item.roomSoinsTypeIds ?? [], item.je?.types ?? [])
    const friday = this.getCommonSoinsTypes(item.roomSoinsTypeIds ?? [], item.ve?.types ?? [])
    return (!!item.lu?.user && monday.length === 0) ||
      (!!item.ma?.user && tuesday.length === 0) ||
      (!!item.me?.user && wednesday.length === 0) ||
      (!!item.je?.user && thursday.length === 0) ||
      (!!item.ve?.user && friday.length === 0)
  }

  public getTypeName (typeId: number) {
    return getSoinName(typeId)
  }

  public getTypeNameShort (typeId: number) {
    return getSoinNameShort(typeId)
  }

  public getCommonSoinsTypes (roomSoinsTypeIds: number[], nurseSoinIds: number[]) {
    return RoomHelpers.GetCommonSoinsTypes(roomSoinsTypeIds, nurseSoinIds)
  }

  public add () {
    const path = `/administration/rooms/planning`
    this.$router.push(path)
  }

  public edit (itemId: number) {
    const path = `/administration/rooms/planning/${itemId}`
    this.$router.push(path)
  }

  public duplicate (itemId: number) {
    const path = `/administration/rooms/planning/${itemId}/true`
    this.$router.push(path)
  }

  public canEditPlanning (item: RoomPlanningPeriodModel) {
    return RoomHelpers.CanEditPlanning(item) && this.hasWriteAccess()
  }

  public canDeletePlanning (item: RoomPlanningPeriodModel) {
    return RoomHelpers.CanDeletePlanning(item) && this.hasWriteAccess()
  }

  public displayDeleteConfirmDialog (item: RoomPlanningPeriodModel) {
    this.selectedRoomPlanning = item
    this.showConfirm = true
  }

  public async confirmDeleteCallback (value: boolean) {
    this.showConfirm = false
    if (value) {
      this.isLoading = true
      if (value) {
        const results = await this.roomService.deleteRoomPlanningPeriod(this.selectedRoomPlanning.id)
          .catch(async (errs) => {
            const res = await ErrorService.handleError(errs)
            this.errorMessages = res.errors
          }).finally(() => {
            this.isLoading = false
            this.selectedRoomPlanning = {}
          })

        if (results) {
          this.errorMessages = []
          this.selectedRoomPlanning = {}
          this.getAllRoomPlannings()
        }
      }
    }
  }

  public hideAlert () {
    this.errorMessages = []
  }

  public hasWriteAccess () {
    return ModuleAuthorisationManager.HasAccess('admin.roomManage.writeOperations')
  }
}
